/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// const transitionDelay = 1500

// exports.shouldUpdateScroll = ({ pathname }) => {
//   // // We use a temporary hack here, see #7758
//   if (window.__navigatingToLink) {
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
//   } else {
//     const savedPosition = JSON.parse(
//       window.sessionStorage.getItem(`@@scroll|${pathname}`)
//     )
//     window.setTimeout(() => window.scrollTo(...savedPosition), transitionDelay)
//   }
//   return false
// }

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {

  // if (location.pathname === '/') {
  //   setTimeout(() => {
  //     window.scrollTo(...[0, 0]);
  //   }, 250);
  // }

  return false;
};

// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   const { pathname } = location
//   // list of routes for the scroll-to-top-hook
//   const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
//   // if the new route is part of the list above, scroll to top (0, 0)
//   if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//     window.scrollTo(0, 0)
//   }

//   return false
// }
